import { styled, Theme } from '@mui/material'
import { rem } from 'polished'

import { Variant } from '@acre/design-system'

export const SearchWrapper = styled('div')`
  position: relative; /* Keep absolute children contained */
  z-index: ${({ theme }) => theme.display.overflowZIndex + 1};
  font-size: 14px;
`

const rowWrapperCompactStyles = ({ theme }: { theme: Theme }) => ({
  alignItems: 'center',
  flexDirection: 'row' as 'row',
  '& label': {
    width: '33%',
    minWidth: '33%',
    textAlign: 'right',
    fontSize: '12px',
    lineHeight: theme.typography.pxToRem(14),
    marginTop: `-${theme.spacers.size12}`,
    marginBottom: `-${theme.spacers.size12}`,
    transform: `translateX(-${theme.spacers.size8})`,
  },
  '& button': {
    marginLeft: theme.spacers.size8,
  },
})

const rowWrapperNewCompactStyles = ({ theme }: { theme: Theme }) => ({
  alignItems: 'center',
  flexDirection: 'row' as 'row',
  '& label': {
    width: '30%',
    minWidth: '30%',
    textAlign: 'left',
    fontSize: '12px',
    lineHeight: theme.typography.pxToRem(14),
    marginRight: theme.spacers.size8,
  },
  '& button': {
    marginLeft: theme.spacers.size8,
  },
})

export const RowWrapper = styled('div')<{ variant?: Variant }>(({ theme, variant }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  '& button': {
    marginLeft: theme.spacers.size16,
  },
  /* 
    Annoying edge case:
    - the first > div is the flex box item, 
    - the second row div is the wrapper inside the component we're trying to target
   */
  '& > div > div': {
    flexGrow: 1,
  },
  ...(variant === 'compact' && rowWrapperCompactStyles({ theme })),
  ...(variant === 'compactLeftAligned' && rowWrapperNewCompactStyles({ theme })),
}))

const inputWrapperCompactStyles = ({ theme }: { theme: Theme }) => ({
  flexDirection: 'column' as 'column',
  marginLeft: 0,

  '& div': {
    marginRight: 0,
    '& + div': {
      marginTop: theme.spacers.size16,
      marginRight: 0,
    },
  },
})

export const InputWrapper = styled('div')<{ variant?: Variant }>(({ variant, theme }) => ({
  display: 'flex',
  '& div': {
    width: '100%',
    marginRight: theme.display.ms1,
    '& + div': {
      marginRight: 0,
    },
  },
  marginTop: theme.spacers.size16,

  ...((variant === 'compact' || variant === 'compactLeftAligned') && inputWrapperCompactStyles({ theme })),
}))

export const SingleSelectorContainer = styled('div')`
  margin-top: ${({ theme }) => rem(theme.display.spacingIncrement * 4)};
`

export const SearchResultButton = styled('button')`
  width: 100%;
  text-align: left;
  padding: ${({ theme }) => theme.spacers.size16};
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  transition:
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colours.primary};
    background-color: ${({ theme }) => theme.colours.primary10};
  }
`

export const Results = styled('ul')<{ isVisible: boolean; variant: Variant }>`
  margin-top: ${({ variant, theme }) => (variant === 'compactLeftAligned' ? theme.spacers.size8 : 'auto')};
  position: ${({ variant }) => (variant === 'compactLeftAligned' ? 'static' : 'absolute')};
  top: ${({ variant }) => (variant === 'compact' ? '40px' : '80px')};
  width: 100%;
  background-color: white;
  list-style: none;
  padding: 0;
  box-shadow: ${({ theme }) => theme.boxShadow};
  border-radius: ${({ theme }) => theme.display.borderRadius};
  overflow: auto;
  max-height: 300px;
  border: ${({ theme }) => `1px solid ${theme.colours.baseLight}`};
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`

export const NoResultLi = styled('li')`
  padding: ${({ theme }) => theme.display.ms1};
  color: ${({ theme }) => theme.colours.baseMid};
`

export const GreyText = styled('span')`
  color: ${({ theme }) => theme.colours.baseMid};
  white-space: nowrap;
`

export const BlueText = styled('span')`
  color: ${({ theme }) => theme.colours.primary};
`
