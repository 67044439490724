import theme from '../theme'
import { ColourId } from './types'

const colourLookup = {
  [ColourId.LightRed]: theme.colours.lightRed,
  [ColourId.LightPurple]: theme.colours.lightPurple,
  [ColourId.LightBlue]: theme.colours.lightBlue,
  [ColourId.BaseLightColor]: theme.colours.baseLight,
  [ColourId.LightGreen]: theme.colours.lightGreen,
  [ColourId.LightOrange]: theme.colours.lightOrange,
  [ColourId.DarkYellow]: theme.colours.lightYellow,
  [ColourId.Orange]: theme.colours.orange,
  [ColourId.BabyPink]: theme.colours.babyPink,
  [ColourId.Pink]: theme.colours.pink,
  [ColourId.Purple]: theme.colours.purple,
  [ColourId.SkyBlue]: theme.colours.skyBlue,
  [ColourId.Green]: theme.colours.green,
  [ColourId.Periwinkle]: theme.colours.periwinkle,
  [ColourId.Yellow]: theme.colours.new.yellow1,
  [ColourId.Grey]: theme.colours.grey,
  [ColourId.AcreBlue]: theme.colours.primary,
  [ColourId.PattensBlue]: theme.colours.pattensBlue,
  [ColourId.Cobalt]: theme.colours.cobalt,
  [ColourId.BaseMid]: theme.colours.baseMid,
  [ColourId.White]: theme.colours.foreground,
  [ColourId.Black]: theme.colours.base,
  [ColourId.Indigo]: theme.colours.oryPurple,
  [ColourId.OryPurple]: theme.colours.oryPurple,
  [ColourId.Red]: theme.colours.new.pink4,
  [ColourId.OryGreen]: theme.colours.portalOptions.lightGreen,
}

export const getColourHex = (colourId: ColourId) => colourLookup[colourId]
