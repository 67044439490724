import React, { memo, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material'

import testHandle from '../../utils/testHandle'
import { CenteredLoadingSpinner } from '../LoadingSpinner'
import ZoomControls from './ZoomControls'
import { Div, FullWidthPdfDocument, StyledPage, ZoomControlsWrapper } from './styles'

const stepSize = 0.2
const minScale = 0.6
const maxScale = 1.4

const useReactPdf = () => {
  const [loading, setLoading] = useState(true)
  const [module, setModule] = useState<typeof import('react-pdf')>()

  useEffect(() => {
    // TODO: Change this to use the vite worker for performance
    // Use the pdf.js worker to ensure pdf files are rendered in a separate thread
    // https://github.com/wojtekmaj/react-pdf#enable-pdfjs-worker
    import('react-pdf').then((reactPdf) => {
      setModule(reactPdf)
      setLoading(false)
    })
  }, [])

  return {
    loading,
    module,
  }
}

type Props = {
  base64: string
  id?: string
  onLoad?: () => void
  showControls?: boolean
  showBorder?: boolean
}

type PdfType = {
  numPages: number
}

const PdfRenderer = ({ id = '', base64, onLoad, showControls = true, showBorder = true }: Props) => {
  const theme = useTheme()
  const [numPages, setNumPages] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [scale, setScale] = useState(maxScale)

  const handleLoadSuccess = ({ numPages }: PdfType) => {
    setLoaded(true)
    setNumPages(numPages)
    onLoad && onLoad()
  }

  const handleUpdateScale = (stepSize: number) => {
    const newScale = Math.min(maxScale, Math.max(minScale, scale + stepSize))
    setScale(newScale)
  }

  const { module: reactPdf, loading } = useReactPdf()

  if (loading) {
    return <CenteredLoadingSpinner />
  }

  /**
   * Below we render the styled components "as" the
   * actual components, since we lazy load react-pdf
   */
  return (
    <Div id={id} data-testid={testHandle(id)}>
      {reactPdf?.Document && (
        <FullWidthPdfDocument
          as={reactPdf.Document}
          file={`data:application/pdf;base64,${base64}`}
          onLoadSuccess={handleLoadSuccess}
          loading={<CenteredLoadingSpinner />}
          fullWidth={!loaded}
        >
          {Array.from(new Array(numPages), (el, index) => {
            const nextIndex = index + 1
            return (
              <Box key={index} mb={theme.spacers.size32}>
                <StyledPage
                  key={`page_${nextIndex}`}
                  pageNumber={nextIndex}
                  scale={scale}
                  loading={<CenteredLoadingSpinner />}
                  border={showBorder}
                  as={reactPdf!.Page}
                />
              </Box>
            )
          })}
        </FullWidthPdfDocument>
      )}
      {showControls && (
        <ZoomControlsWrapper>
          <ZoomControls stepSize={stepSize} updateScale={handleUpdateScale} />
        </ZoomControlsWrapper>
      )}
    </Div>
  )
}

export default memo(PdfRenderer)
