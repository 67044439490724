import React, {
  FC,
  KeyboardEvent,
  KeyboardEventHandler,
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react'
import { PropsWithChildren } from 'react'
import { createContext } from 'react'
import { useContext } from 'react'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import { Location } from 'history'
import { createPortal } from 'react-dom'
import FocusLock from 'react-focus-lock'
import { useLocation } from 'react-router-dom'

import testHandle from '../../utils/testHandle'
import { ModalSize } from '../../utils/types'
import { trackEventFiltered } from '../UserTracker'
import { getHandleKeyDown, getModalClose, PanelBarSections, panelDefault, useModalEffect } from './Modal.helpers'
import ModalPanelBar from './ModalPanelBar'
import {
  ModalBody,
  ModalFooter,
  ModalInnerContainer,
  ModalOverlay,
  ModalTransformation,
  TransformModal,
} from './Modal.styles'

export type ModalProps = PropsWithChildren<{
  open: boolean
  size?: ModalSize
  footer?: ReactElement | null
  header?: PanelBarSections
  heading?: ReactElement
  centered?: boolean
  showHeading?: boolean
  closeButton?: boolean
  transformation?: ModalTransformation
  hideOverflow?: boolean
  onKeyDown?: (e: KeyboardEvent) => void
  onClose?: (modalState: boolean) => void
  className?: string
  id?: string
  padding?: boolean
  fixedHeight?: boolean
  backgroundColor?: string
}>

type ModalHeadingContextType = {
  panels: PanelBarSections
  set: (values: PanelBarSections) => void
}

const modalHeadingContextDefault = { panels: panelDefault, set: () => null }

const ModalHeadingsContext = createContext<ModalHeadingContextType>(modalHeadingContextDefault)

type ModalHeadingProps = PropsWithChildren<{
  location: 'center' | 'left' | 'right'
}>

const ModalHeadingContent: FC<ModalHeadingProps> = ({ location, children }) => {
  const { panels, set } = useContext(ModalHeadingsContext)

  useEffect(() => {
    set({
      ...panels,
      [location]: children,
    })
  }, [children, location])

  return null
}

const Modal = ({
  children,
  open,
  closeButton = true,
  onClose,
  size = 'medium',
  centered = false,
  header = panelDefault,
  footer,
  transformation,
  onKeyDown,
  hideOverflow,
  showHeading = true,
  className,
  id,
  padding = true,
  fixedHeight = false,
  backgroundColor,
}: ModalProps) => {
  const modalRef: MutableRefObject<any> = useRef()
  const modalOverlayRef: MutableRefObject<any> = useRef()
  const { trackEvent } = useMatomo()
  const [headerPanels, setHeaderPanels] = useState<PanelBarSections>(header)

  // Yes, this is insane but there's no good way to mock `useLocation` with react-router v5
  let location: Location
  try {
    location = useLocation() as Location //eslint-disable-line react-hooks/rules-of-hooks
  } catch {
    //no-op
  }

  const handleClose = getModalClose(onClose, () => {
    trackEventFiltered(trackEvent, location, 'modalClose', id || 'unknown')
  })

  const handleKeydown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    getHandleKeyDown(onClose)(e)
    if (onKeyDown) {
      onKeyDown(e)
    }
  }

  useEffect(() => {
    if (modalOverlayRef.current && open) {
      modalOverlayRef.current.focus()
    }
  }, [modalOverlayRef.current, open])

  useModalEffect(open, modalRef)

  return open
    ? createPortal(
        <TransformModal transformation={transformation}>
          <FocusLock>
            <ModalOverlay
              className="modal-overlay"
              ref={modalOverlayRef}
              onKeyDown={handleKeydown}
              tabIndex={0}
              data-testid={testHandle('ModalOverlay')}
              id={id}
            >
              <ModalInnerContainer
                className="modal-inner-container"
                ref={(elem) => (modalRef.current = elem)}
                centered={centered}
                size={size}
                aria-modal
                fixedHeight={fixedHeight}
                // stop bubbling up events to parent
                onClick={(e) => e.stopPropagation()}
              >
                {showHeading && (
                  <ModalPanelBar
                    backgroundColor={backgroundColor}
                    id="ModalHeader"
                    left={headerPanels.left}
                    center={headerPanels.center}
                    right={headerPanels.right}
                    shouldRenderCloseButton={closeButton}
                    handleClose={handleClose}
                    className={className}
                  />
                )}
                <ModalBody
                  showHeading={showHeading}
                  size={size}
                  hideOverflow={hideOverflow}
                  hasFooter={!!footer}
                  className={`${className} modal-body`}
                  padding={padding}
                  data-testid={testHandle('ModalBody')}
                  fixedHeight={fixedHeight}
                >
                  <ModalHeadingsContext.Provider value={{ panels: headerPanels, set: setHeaderPanels }}>
                    <>{children}</>
                  </ModalHeadingsContext.Provider>
                </ModalBody>
                {footer && (
                  <ModalFooter data-testid={testHandle('ModalFooter')} className={className}>
                    {footer}
                  </ModalFooter>
                )}
              </ModalInnerContainer>
            </ModalOverlay>
          </FocusLock>
        </TransformModal>,
        // @ts-ignore
        document.getElementById('modal-root'),
      )
    : null
}

export default Modal
export { ModalHeadingContent }
