import React, { ReactNode, SyntheticEvent } from 'react'
import { Box } from '@mui/material'
import { useIntl } from 'react-intl'

import testHandle from '../../utils/testHandle'
import { CloseButtonSmaller, ModalPanel, ModalPanelContainer } from './Modal.styles'
import { Close } from '../../assets'

type ModalPanelBarProps = {
  id: string
  left?: ReactNode
  center?: ReactNode
  right?: ReactNode
  shouldRenderCloseButton?: boolean
  handleClose?: () => void | undefined
  className?: string
  backgroundColor?: string
}

const ModalPanelBar = ({
  id,
  left,
  center,
  right,
  shouldRenderCloseButton,
  handleClose,
  className,
  backgroundColor,
}: ModalPanelBarProps) => {
  const intl = useIntl()
  const formatMessage = (id: string) => intl.formatMessage({ id })

  const closeButton = (
    <>
      {shouldRenderCloseButton && handleClose && (
        <CloseButtonSmaller
          aria-label={formatMessage('accessibility.closeModal')}
          data-testid={testHandle('CloseModal')}
          onClick={(e: SyntheticEvent) => {
            e.stopPropagation()
            handleClose()
          }}
        >
          <Close />
        </CloseButtonSmaller>
      )}
    </>
  )

  return (
    <ModalPanel className={`modal-panel ${className}`} backgroundColor={backgroundColor ? backgroundColor : ''}>
      <ModalPanelContainer data-testid={testHandle(id)}>
        <Box display="flex" alignItems="center" justifyContent="flex-start" flex="5">
          {left}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          {center}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <>
            {right}
            {closeButton}
          </>
        </Box>
      </ModalPanelContainer>
    </ModalPanel>
  )
}

export default ModalPanelBar
