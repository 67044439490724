export type ErrorMessagesKey =
  | 'DateError.after'
  | 'DateError.afterLabel'
  | 'DateError.before'
  | 'DateError.beforeLabel'
  | 'DateError.beforeTermEnd'
  | 'DateError.day'
  | 'DateError.future'
  | 'DateError.generic'
  | 'DateError.incomplete'
  | 'DateError.input'
  | 'DateError.month'
  | 'DateError.mustBeInFuture'
  | 'DateError.validDateRequired'
  | 'DateError.overAge'
  | 'DateError.past'
  | 'DateError.underAge'
  | 'DateError.endAfterStartDate'
  | 'DateError.startBeforeEndDate'
  | 'DateError.renewalCommissionStartDateMustBeGreaterThanInitialCommissionEndDate'
  | 'DateError.numberOfMonthsRenewalCommissionStartsIn'
  | 'DateError.balanceDateAfterStart'
  | 'errors.accountNumber'
  | 'errors.clientIsNotOnThisBenefit'
  | 'errors.couldNotRemoveRecommendation'
  | 'errors.couldNotSave'
  | 'errors.couldNotUpload'
  | 'errors.noCredentialEmail'
  | 'errors.email'
  | 'errors.generic'
  | 'errors.greaterThan'
  | 'errors.greaterThanZero'
  | 'errors.greaterThan0OnPartAndPartMortgage'
  | 'errors.insufficient'
  | 'errors.groundRentExceedsValuation'
  | 'errors.inputMustBeLetter'
  | 'errors.inputMustBeNumber'
  | 'errors.inputMustBePositiveNumber'
  | 'errors.integer'
  | 'errors.interestRateTooHighForMortgage'
  | 'errors.invalidFormat'
  | 'errors.invalidMonth'
  | 'errors.lessThan'
  | 'errors.maxFloors'
  | 'errors.noOptions'
  | 'errors.nonNegative'
  | 'errors.oneOfTypeAccountNumberSortCode'
  | 'errors.percentage'
  | 'errors.phone'
  | 'errors.positive'
  | 'errors.postcode'
  | 'errors.required'
  | 'errors.benefitRequired'
  | 'errors.select'
  | 'errors.serviceChargeExceedsValuation'
  | 'errors.sortCode'
  | 'errors.upload'
  | 'errors.url'
  | 'errors.validValue'
  | 'errors.yearBuiltTooFarInFuture'
  | 'errors.loanToValueError'
  | 'errors.nameChangeDateExceedsDob'
  | 'errors.clientDobExceedsChildDob'
  | 'errors.clientDobSubceedsParentDob'
  | 'errors.clientDobExceedsMoveInDate'
  | 'errors.couldNotRemoveSolution'
  | 'errors.mobileOrOfficePhoneRequired'
  | 'errors.userWithEmailExists'
  | 'errors.cannotCompleteCase'
  | 'errors.cannotBothBeTrue'
  | 'errors.borrowingRange'
  | 'errors.fcaNumber'
  | 'errors.mustSelectConveyancingType'
  | 'errors.propertyPriceRange'
  | 'errors.propertyRoomRange'
  | 'errors.termGreaterThan100Years'
  | 'errors.depositRange'
  | 'errors.percentage99'
  | 'errors.lessThanLoanAmount'
  | 'errors.firstName'
  | 'errors.surname'
  | 'errors.firstNameLetters'
  | 'errors.surnameLetters'
  | 'errors.middleNameLetters'
  | 'errors.atLeastOneClientRequiredForReferral'
  | 'errors.coverAmount'
  | 'errors.termLength'
  | 'errors.termLengthFamilyBenefit'
  | 'errors.coverAmountAnnual'
  | 'errors.maxFiveDependants'
  | 'errors.oneOfDebtFieldsRequired'
  | 'errors.maxThree'
  | 'errors.invalidRetirementAge'
  | 'errors.isMoreThanSeventy'
  | 'errors.termMoreThanAge'
  | 'errors.invalidNi'
  | 'errors.notEngland'
  | 'errors.mustBeGreaterThanZero'
  | 'errors.orgNameTooLong'

export const ERROR_AFTER_LABEL: ErrorMessagesKey = 'DateError.afterLabel'
export const ERROR_BEFORE_LABEL: ErrorMessagesKey = 'DateError.beforeLabel'
export const ERROR_AFTER_GENERIC_LABEL: ErrorMessagesKey = 'DateError.endAfterStartDate'
export const ERROR_BEFORE_GENERIC_LABEL: ErrorMessagesKey = 'DateError.startBeforeEndDate'
export const ERROR_DATE_GENERIC: ErrorMessagesKey = 'DateError.generic'
export const ERROR_VALID_DATE_REQUIRED: ErrorMessagesKey = 'DateError.validDateRequired'
export const ERROR_DAY: ErrorMessagesKey = 'DateError.day'
export const ERROR_INVALID_NI: ErrorMessagesKey = 'errors.invalidNi'
export const ERROR_FUTURE: ErrorMessagesKey = 'DateError.future'
export const ERROR_INPUT: ErrorMessagesKey = 'DateError.input'
export const ERROR_MONTH: ErrorMessagesKey = 'DateError.month'
export const ERROR_MUST_BE_IN_FUTURE: ErrorMessagesKey = 'DateError.mustBeInFuture'
export const ERROR_OVER_AGE: ErrorMessagesKey = 'DateError.overAge'
export const ERROR_PAST: ErrorMessagesKey = 'DateError.past'
export const ERROR_UNDER_AGE: ErrorMessagesKey = 'DateError.underAge'
export const ERROR_DATE_BEFORE_TERM_END: ErrorMessagesKey = 'DateError.beforeTermEnd'
export const ERROR_COMMISSION_RENEWAL_START_DATE: ErrorMessagesKey =
  'DateError.renewalCommissionStartDateMustBeGreaterThanInitialCommissionEndDate'
export const ERROR_NUMBER_OF_MONTHS_RENEWAL_COMMISSION_STARTS_IN: ErrorMessagesKey =
  'DateError.numberOfMonthsRenewalCommissionStartsIn'
export const ERROR_BALANCE_DATE_AFTER_START: ErrorMessagesKey = 'DateError.balanceDateAfterStart'
export const ERROR_ACCOUNT_NUMBER: ErrorMessagesKey = 'errors.accountNumber'
export const ERROR_CLIENT_IS_NOT_ON_THIS_BENEFIT: ErrorMessagesKey = 'errors.clientIsNotOnThisBenefit'
export const ERROR_COULD_NOT_REMOVE_RECOMMENDATION: ErrorMessagesKey = 'errors.couldNotRemoveRecommendation'
export const ERROR_COULD_NOT_REMOVE_SOLUTION: ErrorMessagesKey = 'errors.couldNotRemoveSolution'
export const ERROR_COULD_NOT_SAVE: ErrorMessagesKey = 'errors.couldNotSave'
export const ERROR_COULD_NOT_UPLOAD: ErrorMessagesKey = 'errors.couldNotUpload'
export const ERROR_NO_CREDENTIAL_EMAIL: ErrorMessagesKey = 'errors.noCredentialEmail'
export const ERROR_EMAIL: ErrorMessagesKey = 'errors.email'
export const ERROR_GENERIC: ErrorMessagesKey = 'errors.generic'
export const ERROR_GREATER_THAN: ErrorMessagesKey = 'errors.greaterThan'
export const ERROR_MAX_FIVE: ErrorMessagesKey = 'errors.maxFiveDependants'
export const ERROR_INSUFFICIENT: ErrorMessagesKey = 'errors.insufficient'
export const ERROR_GROUND_RENT_EXCEEDS_VALUATION: ErrorMessagesKey = 'errors.groundRentExceedsValuation'
export const ERROR_INPUT_MUST_BE_LETTER: ErrorMessagesKey = 'errors.inputMustBeLetter'
export const ERROR_INPUT_MUST_BE_NUMBER: ErrorMessagesKey = 'errors.inputMustBeNumber'
export const ERROR_RETIREMENT_AGE_MUST_BE_NUMBER: ErrorMessagesKey = 'errors.invalidRetirementAge'
export const ERROR_INPUT_MUST_BE_POSITIVE_NUMBER: ErrorMessagesKey = 'errors.inputMustBePositiveNumber'
export const ERROR_INTEGER: ErrorMessagesKey = 'errors.integer'
export const ERROR_INTEREST_RATE_TOO_HIGH_FOR_MORTGAGE: ErrorMessagesKey = 'errors.interestRateTooHighForMortgage'
export const ERROR_INVALID_FORMAT: ErrorMessagesKey = 'errors.invalidFormat'
export const ERROR_INVALID_MONTH: ErrorMessagesKey = 'errors.invalidMonth'
export const ERROR_LESS_THAN: ErrorMessagesKey = 'errors.lessThan'
export const ERROR_MAX_FLOORS: ErrorMessagesKey = 'errors.maxFloors'
export const ERROR_NON_NEGATIVE: ErrorMessagesKey = 'errors.nonNegative'
export const ERROR_MAX_THREE: ErrorMessagesKey = 'errors.maxThree'
export const ERROR_GREATER_THAN_ZERO: ErrorMessagesKey = 'errors.greaterThanZero'
export const ERROR_MUST_BE_GREATER_THAN_ZERO: ErrorMessagesKey = 'errors.mustBeGreaterThanZero'
export const ERROR_NO_OPTIONS: ErrorMessagesKey = 'errors.noOptions'
export const ERROR_ONE_OF_TYPE_ACCOUNT_NUMBER_SORT_CODE: ErrorMessagesKey = 'errors.oneOfTypeAccountNumberSortCode'
export const ERROR_PERCENTAGE: ErrorMessagesKey = 'errors.percentage'
export const ERROR_PERCENTAGE_99: ErrorMessagesKey = 'errors.percentage99'
export const ERROR_LESS_THAN_LOAN_AMOUNT: ErrorMessagesKey = 'errors.lessThanLoanAmount'
export const ERROR_PHONE: ErrorMessagesKey = 'errors.phone'
export const ERROR_POSITIVE: ErrorMessagesKey = 'errors.positive'
export const ERROR_REQUIRED: ErrorMessagesKey = 'errors.required'
export const ERROR_BENEFIT_REQUIRED: ErrorMessagesKey = 'errors.benefitRequired'
export const ERROR_SELECT: ErrorMessagesKey = 'errors.select'
export const ERROR_SERVICE_CHARGE_EXCEEDS_VALUATION: ErrorMessagesKey = 'errors.serviceChargeExceedsValuation'
export const ERROR_SORT_CODE: ErrorMessagesKey = 'errors.sortCode'
export const ERROR_UPLOAD: ErrorMessagesKey = 'errors.upload'
export const ERROR_URL: ErrorMessagesKey = 'errors.url'
export const ERROR_VALID_VALUE: ErrorMessagesKey = 'errors.validValue'
export const ERROR_YEAR_BUILT_TOO_FAR_IN_FUTURE: ErrorMessagesKey = 'errors.yearBuiltTooFarInFuture'
export const ERROR_LOAN_TO_VALUE: ErrorMessagesKey = 'errors.loanToValueError'
export const ERROR_NAME_CHANGE_DATE_EXCEEDS_DOB: ErrorMessagesKey = 'errors.nameChangeDateExceedsDob'
export const ERROR_CLIENT_DOB_EXCEEDS_CHILD_DOB: ErrorMessagesKey = 'errors.clientDobExceedsChildDob'
export const ERROR_CLIENT_DOB_SUBCEEDS_PARENT_DOB: ErrorMessagesKey = 'errors.clientDobSubceedsParentDob'
export const ERROR_CLIENT_DOB_EXCEEDS_MOVE_IN_DATE: ErrorMessagesKey = 'errors.clientDobExceedsMoveInDate'
export const ERROR_PHONE_NUMBER_REQUIRED: ErrorMessagesKey = 'errors.mobileOrOfficePhoneRequired'
export const ERROR_USER_WITH_EMAIL_EXISTS: ErrorMessagesKey = 'errors.userWithEmailExists'
export const ERROR_ORG_NAME_TOO_LONG: ErrorMessagesKey = 'errors.orgNameTooLong'
export const ERROR_CANNOT_COMPLETE_CASE: ErrorMessagesKey = 'errors.cannotCompleteCase'
export const ERROR_CANNOT_BOTH_BE_TRUE: ErrorMessagesKey = 'errors.cannotBothBeTrue'
export const ERROR_POSTCODE: ErrorMessagesKey = 'errors.postcode'
export const ERROR_BORROWING_RANGE: ErrorMessagesKey = 'errors.borrowingRange'
export const ERROR_FCA_NUMBER: ErrorMessagesKey = 'errors.fcaNumber'
export const ERROR_MUST_SELECT_CONVEYANCING_TYPE = 'errors.mustSelectConveyancingType'
export const ERROR_PROPERTY_PRICE_RANGE: ErrorMessagesKey = 'errors.propertyPriceRange'
export const ERROR_PROPERTY_ROOM_RANGE: ErrorMessagesKey = 'errors.propertyRoomRange'
export const ERROR_TERM_MAX: ErrorMessagesKey = 'errors.termGreaterThan100Years'
export const ERROR_DEPOSIT_RANGE: ErrorMessagesKey = 'errors.depositRange'
export const ERROR_GREATER_THAN_0_ON_PART_AND_PART_MORTGAGE: ErrorMessagesKey =
  'errors.greaterThan0OnPartAndPartMortgage'
export const ERROR_FIRST_NAME: ErrorMessagesKey = 'errors.firstName'
export const ERROR_SURNAME: ErrorMessagesKey = 'errors.surname'
export const ERROR_FIRST_NAME_LETTERS: ErrorMessagesKey = 'errors.firstNameLetters'
export const ERROR_SURNAME_LETTERS: ErrorMessagesKey = 'errors.surnameLetters'
export const ERROR_MIDDLE_NAME_LETTERS: ErrorMessagesKey = 'errors.middleNameLetters'
export const AT_LEAST_ONE_CLIENT_REQUIRED_FOR_REFERRAL: ErrorMessagesKey = 'errors.atLeastOneClientRequiredForReferral'
export const PROTECTION_COVER_AMOUNT: ErrorMessagesKey = 'errors.coverAmount'
export const INCOME_PROTECTION_CANNOT_BE_BELOW_5_YEARS: ErrorMessagesKey = 'errors.termLength'
export const COVER_AMOUNT_CANNOT_BE_LESS_THAN_1000: ErrorMessagesKey = 'errors.coverAmountAnnual'
export const TERM_LENGTH_CANNOT_BE_LESS_THAN_1: ErrorMessagesKey = 'errors.termLengthFamilyBenefit'
export const ONE_OF_REQUIRED_DEBT_FIELDS: ErrorMessagesKey = 'errors.oneOfDebtFieldsRequired'
export const AGE_IS_MORE_THAN_SEVENTY: ErrorMessagesKey = 'errors.isMoreThanSeventy'
export const TERM_SUM_IS_MORE_THAN_SEVENTY: ErrorMessagesKey = 'errors.termMoreThanAge'
export const ERROR_NOT_ENGLAND: ErrorMessagesKey = 'errors.notEngland'
