import { styled } from '@mui/material'

import { LegacyThemeType } from '@acre/design-system'

export const PdfDocWrapper = styled('div')`
  height: 400px;
  overflow: scroll;
`

export const UnsetMarginWrapper = styled('div')<{
  margin: keyof LegacyThemeType['spacers']
}>`
  margin-left: -${({ margin, theme }) => theme.spacers[margin]};
  margin-right: -${({ margin, theme }) => theme.spacers[margin]};
`
